import additives from "../assets/food_alert_icons/additives.svg";
import alcohol_allergy from "../assets/food_alert_icons/alcohol_allergy.svg";
import artificial_coulourings_e132 from "../assets/food_alert_icons/artificial_coulourings_e132.svg";
import artificial_coulourings_e143 from "../assets/food_alert_icons/artificial_coulourings_e143.svg";
import caffeine from "../assets/food_alert_icons/caffeine.svg";
import casein from "../assets/food_alert_icons/casein.svg";
import chill_peppers_allergy from "../assets/food_alert_icons/chill_peppers_allergy.svg";
import eggs from "../assets/food_alert_icons/eggs.svg";
import ergothioneine_allergy from "../assets/food_alert_icons/ergothioneine_allergy.svg";
import fermented from "../assets/food_alert_icons/fermented.svg";
import fish_or_shellfish_allergy from "../assets/food_alert_icons/fish_or_shellfish_allergy.svg";
import fodmap_on_gut from "../assets/food_alert_icons/fodmap_on_gut.svg";
import fried from "../assets/food_alert_icons/fried.svg";
import fruit_allergy from "../assets/food_alert_icons/fruit_allergy.svg";
import garlic_allergy from "../assets/food_alert_icons/garlic_allergy.svg";
import gluten from "../assets/food_alert_icons/gluten.svg";
import goitrogens from "../assets/food_alert_icons/goitrogens.svg";
import heavy_metals from "../assets/food_alert_icons/heavy_metals.svg";
import lactose from "../assets/food_alert_icons/lactose.svg";
import lectins from "../assets/food_alert_icons/lectins.svg";
import meat_allergy from "../assets/food_alert_icons/meat_allergy.svg";
import mushrooms_allergy from "../assets/food_alert_icons/mushrooms_allergy.svg";
import oats_allergy from "../assets/food_alert_icons/oats_allergy.svg";
import only_soy from "../assets/food_alert_icons/only_soy.svg";
import oxalates from "../assets/food_alert_icons/oxalates.svg";
import peanuts from "../assets/food_alert_icons/peanuts.svg";
import phytates from "../assets/food_alert_icons/phytates.svg";
import processed from "../assets/food_alert_icons/processed.svg";
import processed2 from "../assets/food_alert_icons/processed2.svg";
import refined_carbs from "../assets/food_alert_icons/refined_carbs.svg";
import rice_allergy from "../assets/food_alert_icons/rice_allergy.svg";
import saturated_fats_allergy from "../assets/food_alert_icons/saturated_fats_allergy.svg";
import sesame_allergy from "../assets/food_alert_icons/sesame_allergy.svg";
import soy from "../assets/food_alert_icons/soy.svg";
import tree_nut_allergy from "../assets/food_alert_icons/tree_nut_allergy.svg";
import unstable_vegetable_oil from "../assets/food_alert_icons/unstable_vegetable_oil.svg";

import additives_white from "../assets/food_alert_icons/white-icons/additives.svg";
import artificial_coulourings_white from "../assets/food_alert_icons/white-icons/artificial_coulourings.svg";
import caffeine_white from "../assets/food_alert_icons/white-icons/caffeine.svg";
import casein_white from "../assets/food_alert_icons/white-icons/casein.svg";
import eggs_white from "../assets/food_alert_icons/white-icons/eggs.svg";
import fermented_white from "../assets/food_alert_icons/white-icons/fermented.svg";
import fodmap_on_gut_white from "../assets/food_alert_icons/white-icons/fodmap_on_gut.svg";
import fried_white from "../assets/food_alert_icons/white-icons/fried.svg";
import gluten_white from "../assets/food_alert_icons/white-icons/gluten.svg";
import goitrogens_white from "../assets/food_alert_icons/white-icons/goitrogens.svg";
import heavy_metals_white from "../assets/food_alert_icons/white-icons/heavy_metals.svg";
import lactose_white from "../assets/food_alert_icons/white-icons/lactose.svg";
import lectins_white from "../assets/food_alert_icons/white-icons/lectins.svg";
import only_soy_white from "../assets/food_alert_icons/white-icons/only_soy.svg";
import oxalates_white from "../assets/food_alert_icons/white-icons/oxalates.svg";
import peanuts_white from "../assets/food_alert_icons/white-icons/peanuts.svg";
import phytates_white from "../assets/food_alert_icons/white-icons/phytates.svg";
import processed_white from "../assets/food_alert_icons/white-icons/processed.svg";
import processed2_white from "../assets/food_alert_icons/white-icons/processed2.svg";
import refined_carbs_white from "../assets/food_alert_icons/white-icons/refined_carbs.svg";
import soy_white from "../assets/food_alert_icons/white-icons/soy.svg";
import unstable_vegetable_oil_white from "../assets/food_alert_icons/white-icons/unstable_vegetable_oil.svg";

const iconVariantsBlue = {
  additives,
  alcohol_allergy,
  artificial_coulourings_e132,
  artificial_coulourings_e143,
  caffeine,
  casein,
  chill_peppers_allergy,
  eggs,
  ergothioneine_allergy,
  fermented,
  fish_or_shellfish_allergy,
  fodmap_on_gut,
  fried,
  fruit_allergy,
  garlic_allergy,
  gluten,
  goitrogens,
  heavy_metals,
  lactose,
  lectins,
  meat_allergy,
  mushrooms_allergy,
  oats_allergy,
  only_soy,
  oxalates,
  peanuts,
  phytates,
  processed,
  processed2,
  refined_carbs,
  rice_allergy,
  saturated_fats_allergy,
  sesame_allergy,
  soy,
  tree_nut_allergy,
  unstable_vegetable_oil,
};

const iconVariantsWhite = {
  additives_white,
  artificial_coulourings_white,
  caffeine_white,
  casein_white,
  eggs_white,
  fermented_white,
  fodmap_on_gut_white,
  fried_white,
  gluten_white,
  goitrogens_white,
  heavy_metals_white,
  lactose_white,
  lectins_white,
  only_soy_white,
  oxalates_white,
  peanuts_white,
  phytates_white,
  processed_white,
  processed2_white,
  refined_carbs_white,
  soy_white,
  unstable_vegetable_oil_white,
};

/**
 *
 * @param {string} variant
 */
export const getExcipientIcon = (variant: string, color: "blue" | "white") => {
  const variantWithoutFileExtension = variant.slice(0, variant.length - 4);

  if (color === "blue") {
    return (
      <img
        src={
          iconVariantsBlue[
            variantWithoutFileExtension as keyof typeof iconVariantsBlue
          ] || iconVariantsBlue["processed"]
        }
        alt=""
        // style={{
        //   width: "100%",
        //   height: "100%",
        // }}
      />
    );
  } else {
    return (
      <img
        src={
          iconVariantsWhite[
            variantWithoutFileExtension as keyof typeof iconVariantsWhite
          ] || iconVariantsWhite["processed_white"]
        }
        alt=""
      />
    );
  }
};
