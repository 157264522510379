import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoClose } from "react-icons/io5";
import Modal from "react-modal";
import { CompositionType } from "../../../../../../api/types";
import { ReactComponent as CapsuleIcon } from "../../../../../../assets/content/capsule-orange.svg";
import { ReactComponent as StarIcon } from "../../../../../../assets/content/star-blue.svg";
import RoundedProgress from "../../../../../../components/RoundedProgress";
import { useDisableBodyScroll } from "../../../../../../hooks/Scroll/useDisableScroll";
import { useViewport } from "../../../../../../hooks/Viewport/useViewport";

import { motion } from "framer-motion";

import ScrollButtons from "../../../../../../components/ScrollButtons";
import { getAgentExtractTypeIcon } from "../../../../../../utils/getAgentExtractTypeIcon";
import { getAgentFormIcon } from "../../../../../../utils/getAgentFormIcon";
import { getNutrientCategoryIcon } from "../../../../../../utils/getNutrientCategoryIcon";
import { FieldOfActionsTab } from "./components/FieldOfActionsTab";
import { InformationCard } from "./components/InformationCard";
import { PublicationsTab } from "./components/PublicationsTab";
import TabView from "./components/TabView";
import {
  CompositionField,
  CompositionName,
  CompositionTypeContainer,
  Container,
  ContainerIconMock,
  Content,
  Dosage,
  ExtractTypeName,
  HeaderComposition,
  HeaderModal,
  InformationCardContainer,
  NutrientFills,
  NutrientFillsContainer,
  SymptomScrollButtonContainer,
} from "./style";

interface RelatedPublicationsModalProps {
  isOpen: boolean;
  onModalClose: () => void;
  component: CompositionType;
  id: string;
}

const ModalComposition = ({
  isOpen,
  onModalClose,
  component,
  id,
}: RelatedPublicationsModalProps) => {
  const { t } = useTranslation();

  const fieldOfActionsTabRef = useRef<HTMLDivElement>(null);
  const executeFieldOfActionsTabScroll = () => {
    if (fieldOfActionsTabRef && fieldOfActionsTabRef.current) {
      fieldOfActionsTabRef.current.scrollIntoView();
    }
  };
  const carousel = useRef<HTMLDivElement>(null);

  const [width, setWidth] = useState(0);
  const [elementRef, setElementRef] = useState<HTMLElement>();

  function handleGetElementRef(event: HTMLElement) {
    setElementRef(event);
  }

  useEffect(() => {
    setWidth(
      carousel.current?.scrollWidth! - carousel.current?.offsetWidth! + 10
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [[], isOpen]);

  useEffect(() => {
    setWidth(elementRef?.scrollWidth! - elementRef?.offsetWidth! + 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementRef]);

  const { isUnderTablet } = useViewport();

  const customStyles: Modal.Styles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      width: "612px",
      height: "98vh",
      maxWidth: !isUnderTablet ? "max(55vw, 800px)" : "95vw", // TODO ajustar mobile
      border: "none",
      borderRadius: "8px",
      boxShadow: "0px 6px 8px 0px rgba(0, 0, 0, 0.3)",
      padding: "0",
      overflow: "hidden",
      marginLeft: !isUnderTablet ? "40px" : "", // TODO ajustar mobile
    },
    overlay: {
      zIndex: 900,
      background: "rgba(0, 0, 0, 0.5)",
    },
  };

  useDisableBodyScroll(isOpen);

  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      ariaHideApp={false}
      style={customStyles}
      onRequestClose={onModalClose}
    >
      <Container>
        <HeaderModal>
          <IoClose
            size={25}
            fill={"#545976"}
            onClick={onModalClose}
            className="icon-close"
          />
        </HeaderModal>
        <Content>
          <HeaderComposition>
            <RoundedProgress
              size={106}
              progress={component?.score}
              grayForeground
              thickness={6}
            >
              <ContainerIconMock>
                {!!component?.extractTypeId
                  ? getAgentExtractTypeIcon(component?.extractTypeId, 60)
                  : getNutrientCategoryIcon(component?.categoryId)}
                {/* <IconMock /> */}
              </ContainerIconMock>
            </RoundedProgress>

            <CompositionField>
              <CompositionName>{`${component?.name}`}</CompositionName>
              {component?.extractType && (
                <ExtractTypeName>{`${component?.extractType}`}</ExtractTypeName>
              )}
              <Dosage>
                {`${parseFloat(component?.dosageAmountValue.toFixed(2))} ${
                  component?.dosageAmountUnit
                }`}
              </Dosage>
            </CompositionField>

            <NutrientFillsContainer>
              <CompositionTypeContainer>
                {component?.formId && getAgentFormIcon(component?.formId)}
                {component?.form && <span>{component?.form}</span>}
              </CompositionTypeContainer>

              <NutrientFills
                contentLength={
                  t("screens.productDetail.fills_need_day", {
                    nutrientFills: Math.round(component?.score),
                  }).length
                }
              >
                {t("screens.productDetail.fills_need_day", {
                  nutrientFills: Math.round(component?.score),
                })}
              </NutrientFills>
            </NutrientFillsContainer>
          </HeaderComposition>

          {component?.isInTopTen && component?.drugInteractions.length >= 2 && (
            <SymptomScrollButtonContainer>
              <ScrollButtons ref={carousel} length={174} />
            </SymptomScrollButtonContainer>
          )}

          {(component?.isInTopTen || component?.hasDrugInteraction) && (
            <InformationCardContainer>
              <motion.div
                ref={carousel}
                className="carousel"
                onMouseEnter={(event) =>
                  handleGetElementRef(event.currentTarget)
                }
                onMouseOut={(event) => handleGetElementRef(event.currentTarget)}
                onTouchMove={(event) =>
                  handleGetElementRef(event.currentTarget)
                }
                onTap={() => handleGetElementRef}
                onTouchStart={(event) =>
                  handleGetElementRef(event.currentTarget)
                }
                onTouchEnd={(event) => handleGetElementRef(event.currentTarget)}
              >
                <motion.div
                  className="inner-carousel"
                  drag="x"
                  dragConstraints={{ right: 0, left: -width }}
                >
                  {component?.isInTopTen && (
                    <InformationCard
                      componentId={component?.id}
                      topTen={true}
                      text={"Top 10 of your needs"}
                      icon={<StarIcon />}
                    />
                  )}

                  {component?.hasDrugInteraction &&
                    component?.drugInteractions.map((element) => (
                      <InformationCard
                        componentId={component?.id}
                        // topTen={true}
                        text={"Drugs interaction"}
                        hasSubTitle={element.phrase}
                        icon={<CapsuleIcon />}
                      />
                    ))}
                </motion.div>
              </motion.div>
            </InformationCardContainer>
          )}

          <TabView
            labels={[
              `${t("screens.productDetail.fields_of_action")}`,
              `${t("components.modal.publications_tab")}`,
            ]}
            contents={[
              <FieldOfActionsTab
                fieldsOfAction={component?.fieldsOfAction}
                executeScroll={executeFieldOfActionsTabScroll}
                hasHealthRisk={component?.healthRisks.length > 0}
                hasNutrition={component?.nutrition.length > 0}
                healthRisk={component?.healthRisks}
                nutrition={component?.nutrition}
                componentName={component?.name}
              />,
              <PublicationsTab component={component} />,
            ]}
            labelsCounts={[
              component?.fieldsOfAction.length,
              component?.studiesId.length,
            ]}
          />
        </Content>
      </Container>
    </Modal>
  );
};

export { ModalComposition };
