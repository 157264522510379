import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.shadow};
  border-radius: 8px;
  position: relative;
`;

export const Title = styled.label`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  color: ${({ theme }) => theme.colors.black};
  font-weight: bold;
  margin-bottom: 1.5rem;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  // > div {
  //   :not(:first-child) {
  //     margin-left: 0.5rem;
  //   }
  /* } */
`;
