
import ProgressCard from '../ProgressCard';
import { AgentFieldOfActionType, HealthRiskType, NutritionType } from '../../../../../../../../api/types';
import { useRef, useState } from 'react';
import ViewMoreButton from '../../../../../../../../components/Button/ViewMore';
import TabView from '../TabView';
import { HealthRiskCard } from '../HealthRiskCard';
import { ContainerHealthRisk, ScrollButtonContainer } from './style';
import ScrollButtons from '../../../../../../../../components/ScrollButtons';
import { NutritionCard } from '../NutritionCard';
import HorizontalScrollView from '../../../../../../../../components/HorizontalScroll';

interface FiealOfActionsCardsProps {
  fieldsOfAction: AgentFieldOfActionType[]
  executeScroll: () => void
  hasHealthRisk: boolean
  hasNutrition: boolean
  healthRisk: HealthRiskType[]
  nutrition: NutritionType[]
  componentName: string
}

export function FieldOfActionsTab({ fieldsOfAction, executeScroll, hasHealthRisk, hasNutrition, healthRisk, nutrition, componentName }: FiealOfActionsCardsProps) {

  const carouselHealthRisk = useRef<HTMLDivElement>(null)
  const carouselNutrition = useRef<HTMLDivElement>(null)
  const [viewMore, setViewMore] = useState(false)

  const toggleViewMore = () => {
    if (viewMore) {
      executeScroll();
    }
    setViewMore(!viewMore);
  };

  return (
    <>
      {
        fieldsOfAction.map((element, index) => (
          <ProgressCard
            key={element.id}
            viewMore={viewMore}
            index={index}
            title={element.name}
            progress={element.score}
            height={10}
            categoryId={element.categoryId}
            isContraindication={element.isContraindication}
            hasSynergy={element.hasSynergy}
            synergies={element.synergies}
            componentName={componentName}
          />
        ))
      }

      {fieldsOfAction.length > 4 && (
        <ViewMoreButton isOpen={viewMore} onClick={toggleViewMore} />
      )}

      {
        (hasHealthRisk || hasNutrition) && (
          <TabView
            withOutGapAndHeight={healthRisk?.length <= 3 || nutrition?.length <= 1 }
            labels={[
              hasHealthRisk ? `Health Risk` : "",
              hasNutrition ? `Nutrition` : "",
            ]}
            contents={[
              hasHealthRisk && (
                <>
                  {
                    (healthRisk?.length > 3) && (
                      <ScrollButtonContainer>
                        <ScrollButtons ref={carouselHealthRisk} length={185} />
                      </ScrollButtonContainer>
                    )
                  }
                  <ContainerHealthRisk className="horizontal-scroll">
                    <HorizontalScrollView className="horizontal-scroll" ref={carouselHealthRisk}>
                      <HealthRiskCard healthRisk={healthRisk} />
                    </HorizontalScrollView>
                  </ContainerHealthRisk>
                </>
              ),
              hasNutrition && (
                <>
                  {
                    (nutrition?.length > 1) && (
                      <ScrollButtonContainer>
                        <ScrollButtons ref={carouselNutrition} length={570} />
                      </ScrollButtonContainer>
                    )
                  }
                  <ContainerHealthRisk className="horizontal-scroll" >
                    <HorizontalScrollView className="horizontal-scroll" ref={carouselNutrition}>
                      <NutritionCard nutrition={nutrition} />
                    </HorizontalScrollView>
                  </ContainerHealthRisk>
                </>
              )

            ]}
          />
        )
      }
    </>
  )
}