import React from "react";
import { useTranslation } from "react-i18next";
import { Bar, Container, Progress, WrapperTop, WrapperAlert, TopContent } from "./style";
import { getIconsSvgFielOfActionsComposition } from "../../../../../../../../utils/getIconsSvgFielOfActionsComposition";
import { ReactComponent as AlertIcon } from "../../../../../../../../assets/content/alert.svg";
import { ReactComponent as PillSynergyIcon } from "../../../../../../../../assets/content/pill-synergy.svg";
import { AlertContainer } from "../../../style";
import { SynergyType } from "../../../../../../../../api/types";

type ProgressCardType = {
  isContraindication: boolean;
  hasSynergy: boolean;
  progress: number;
  title: string;
  height?: number;
  viewMore: boolean;
  index: number;
  categoryId: number;
  synergies: SynergyType[];
  componentName: string
};

const ProgressCard: React.FC<ProgressCardType> = ({
  isContraindication,
  progress,
  title,
  height,
  viewMore,
  index,
  categoryId,
  hasSynergy,
  synergies,
  componentName
}) => {
  const { t } = useTranslation();

  function handleLowerCase(title: string) {
    const titleLowerCase = title.toLowerCase()
    return titleLowerCase
  }

  return (
    <Container
      viewMore={viewMore}
      index={index}
      isContraindication={isContraindication}
    // hasSynergy={hasSynergy}
    >
      <WrapperTop>
        <TopContent isContraindication={isContraindication}>
          {getIconsSvgFielOfActionsComposition(categoryId)}
          <label>{title}</label>
          {/* <label>{t(`healthGoals.${handleLowerCase(title)}`)}</label> */}
        </TopContent>
        <Progress height={height}>
          <Bar progress={progress} isContraindication={isContraindication} />
        </Progress>
      </WrapperTop>

      {
        synergies.length > 0 && (
          synergies.map(synergy => (
            <WrapperAlert isContraindication={isContraindication}>
              <AlertContainer
              // id={`is-in-top-ten-${categoryId}`}
              // isInTopTen={true}
              >
                <PillSynergyIcon />
              </AlertContainer>

              <span><strong>Synergy: </strong>{synergy.phrase}</span>
            </WrapperAlert>
          ))
        )
      }

      {
        isContraindication && (
          <WrapperAlert isContraindication={isContraindication} >
            <AlertContainer
              id={`is-in-top-ten-${categoryId}`}
              isInTopTen={true}
            >
              <AlertIcon />
            </AlertContainer>

            <span><strong>Contraindication: </strong>{`${componentName} is contraindicated for ${title}`}</span>
          </WrapperAlert>
        )
      }
    </Container>
  );
};

export default ProgressCard;
