const messages = {
  fr: {
    translation: {
      components: {
        sidebar: {
          home: "Accueil",
          productRecommendation: "Recommandations de produits",
          nutritionalNeeds: "Besoins nutritionnels",
          favoriteFoods: "Aliments préférés",
          recommendedPacks: "Packs recommandés",
        },
        shoppingCartSidebar: {
          your_basket: "Votre panier",
          of_your_nutritional_needs: " de vos besoins nutritionnels",
          total: "Total",
          your_compatibility: "Votre compatibilité",
          validate: "Valider",
          remove_all: "Tout supprimer",
        },
        navMobile: {
          the_right_supplements:
            "Les meilleurs compléments alimentaires pour vous",
        },
        modal: {
          already_added:
            "Vous avez déjà ajouté {{ productName }} à votre panier. Voulez-vous le supprimer?",
          yes: "Oui",
          no: "Non",
          read_more: "En savoir plus",
          related_publications: "Publications connexes: {{ componentName }}",
          publications_tab: "publications scientifiques",
        },
        filterSidebar: {
          find_the_best: "Trouvez les meilleurs produits selon vos besoins",
          select_below: "Sélectionnez ci-dessous",
          your_symptoms: "Symptômes spécifiques",
          symptoms_category: "Santé générale",
          your_health_goals: "Vos objectifs de santé",
          clear_filters: "Effacer les filtres",
          apply: "Appliquer",
          price: "Prix",
        },
      },
      languages: {
        actualInitials: "fr",
        actual: "Anglais",
        fr: "Français",
        pt: "Portugais",
        en: "Anglais",
      },
      healthGoals: {
        energy: "Gain en énergie",
        boost: "Maximisation de la performance",
        beauty: "Amélioration esthétique",
        weight: "Perte de poids",
        digestion: "Soutien digestif",
        cognition: "Renforcement des facultés cognitives",
        mood: "Équilibre émotionnel",
        longevity: "Promotion de la longévité",
        sleep: "Optimisation du sommeil",
        immunity: "Protection immunitaire",
      },
      screens: {
        home: {
          hello: "Bonjour {{ name }}!",
          hello_male: "Bonjour {{ name }}!",
          hello_female: "Bonjour {{ name }}!",
          title: "Vos recommandations",
          based_on: "basées sur {{ count }} publications scientifiques",
          based_of: "base de",
          best_products: "Meilleurs Produits",
          best_nutrients: "Meilleurs Nutriments",
          see_our_pack: "Voir notre recommandation de pack",
          see_other_possibilities:
            "Voir d'autres possibilités pour <span>améliorer votre bien-être</span>",
          banner: {
            pack_essential: "Pack Essentiel",
            optimal: "Pack Optimal",
            premium: "Pack Premium",
            recommended_packs: "Packs recommandés",
            pack_supports:
              "Le <strong>{{ packName }}</strong> supporte <strong>{{ packPercentage }}%</strong> de vos besoins de santé",
            add_to_cart: "Ajouter au panier",
            see_all: "Voir tout",
            your_compatibility: "Votre compatibilité",
            in_cart: "Ajouté",
            products: "Produits",
          },
          cardBests: {
            view_all: "Voir tout",
          },
          tabs: {
            bestProducts: {
              based_of_products: "{{ count }} Produits",
              see_all: "Voir tout",
              see_the_most:
                "Voir les suppléments les plus compatibles basés sur",
              your_goals_and_needs: "vos objectifs et besoins de santé:",
              your_compatibility: "Votre Compatibilité",
              health_goals: "Objectifs de Santé",
              symptoms: "Symptômes",
              learn_more: "En savoir plus",
              in_cart: "Ajouté",
            },
            bestNutrients: {
              based_of_nutrients: "{{ count }} Nutriments",
              see_all: "Voir tout",
              see_the_most:
                "Voir les compléments alimentaires les plus compatibles basés sur",
              your_goals_and_needs:
                "Vos objectifs et besoins en matière de santé:",
              your_compatibility: "Votre compatibilité",
              health_goals: "Objectifs de santé",
              symptoms: "Symptômes",
              learn_more: "En savoir plus",
              best_products: "Top Produits",
            },
          },
        },
        products: {
          best_products_from_a_base:
            "<strong>Meilleurs suppléments </strong> à partir d'une base de <label>{{ count }} produits</label>",

          find_the_best: "Trouvez les meilleurs produits selon vos besoins.",
          select_below: "Sélectionnez ci-dessous:",
          amount_available_products: "{{ count }} produits disponibles",
          search_product: "Rechercher un produit",
          filter: "Filtre",
          your_symptoms: "Vos symptômes",
        },
        nutritionalNeeds: {
          best_nutrients: "Meilleurs nutriments recommandés",
          find_the_best: "Trouvez les meilleurs nutriments selon vos besoins.",
          search_nutrient: "Rechercher un nutriment",
          filter: "Filtrer",
          your_symptoms: "Vos symptômes",
        },
        productDetail: {
          alerts: "Alertes",
          precautions: "Précautions",
          your_compatibility: "Votre Compatibilité",
          best_products: "Meilleurs Produits",
          health_goals: "Objectifs de Santé",
          symptoms: "Symptômes",
          composition: "Composition",
          fields_of_action: "Champs d'action",
          excipients: "Excipients",
          capsules_per_day: "{{ count }} capsules par jour",
          capsule_per_day: "{{ count }} capsule par jour",
          month: "mois",
          months: "mois",
          week: "semaine",
          weeks: "semaines",
          and: "et",
          in_cart: "Ajouté",
          add_to_cart: "Ajouter au panier",
          fills_need_day: "Remplit {{ nutrientFills }}% de votre besoin/jour",
          support_your_deficiencies:
            "Supporte vos déficiences nutritionnelles suivantes",
          support_your_health: "Soutient vos objectifs de santé suivants",
          your_diet_supply:
            "Votre alimentation ne fournit que {{score}}% de vos besoins quotidiens recommandés en {{ name }}!",
          symptom: "Symptôme",
          less_details: "Moins de détails",
          more_details: "Plus de détails",
          has_nutrients_in_this_category:
            "<label>{{ amountOfNutrients }}</label> Nutriments dans cette catégorie",
          has_nutrient_in_this_category:
            "<label>{{ amountOfNutrients }}</label> Nutriment dans cette catégorie",
          see_all_related_publications:
            "Voir toutes les publications associées",
          no_additional_information:
            "Pas d'informations supplémentaires relatives à ce nutriment,",
          for_each_capsule: "Pour chaque <span>capsule</span>",
          other_ingredients: "Autres ingrédients",
          per_dose: "par dose",
        },
        recommendedPack: {
          alerts: "Alertes",
          precautions: "Précautions",
          choose_your_pack: "Choisissez votre pack",
          essential_pack: "Pack Essentiel",
          optimal_pack: "Pack Optimal",
          premium_pack: "Pack Premium",
          pack_supports:
            "Le <span>{{ packName }}</span> supporte <span>{{ score }}%</span> de vos besoins de santé",
          score_health_needs: "{{ score }}%",
          duration: "Durée",
          month: "mois",
          months: "mois",
          in_cart: "Ajouté",
          add_to_cart: "Ajouter au panier",
          dosage_of: "Dosage de",
          products_in_pack: "10 produits dans le pack",
          your_benefits: "Bénéfices",
          field_of_action: "Champ d'action",
          learn_more: "En savoir plus",
          your_compatibility: "Votre compatibilité",
          health_goals: "Objectifs de santé",
          symptoms: "Symptômes",
        },
        nutrientDetail: {
          best_products: "Top produits",
          best_products_with_nutrient: "Meilleurs produits avec {{ name }}",
          nutrient_detail: "Détail des nutriments",
          diet_deficiency:
            "Votre alimentation ne remplit que {{ nutritionDeficiency }}% des apports journaliers recommandés pour ce nutriment",
          health_goals: "Objectifs de santé",
          symptoms: "Symptômes",
          fields_of_action: "Champs d'action",
          your_compatibility: "Votre compatibilité",
          products_containing: "Produits contenant ces nutriments",
          day: "jour",
          in_cart: "Ajouté",
          add_to_cart: "Ajouter au panier",
          better_compatibility: "Meilleure compatibilité",
          best_report: "Meilleur rapport qualité / prix",
          support_your_following: "Soutient vos objectifs de santé suivants",
          symptom: "Symptôme",
          less_details: "Moins de détails",
          more_details: "Plus de détails",
        },
        validate: {
          redirect: "Vous serez redirigé vers notre partenaire",
        },
      },
      common: {
        view_more: "Voir plus",
        view_less: "Voir moins",
        alerts: "Alertes",
        precautions: "Précautions",
        contraindication: "Contre-indication",
        study: "Étude",
        studies: "Études",
        and: "et",
        excipient: "Excipient",
        products: "Produits",
        day: "jour",
        drug_interaction: "Interaction médicamenteuse",
        allergy: "Allergie",
        intolerance: "Intolérance",
      },
      hovers: {
        agent_has_drug_interation:
          "Cette nutriment a une interaction avec un médicament",
        agent_has_drug_interations:
          "Ce nutriment a des interactions avec des médicament",
        agent_has_alert: "Alerte associée à ce nutriment",
        agent_has_alerts: "Alertes associées à ce nutriment",
        agent_has_synergy_alert: "This component has synergy",
        agent_has_nutrition_deficient_alert:
          "Vous êtes déficient en cet élément nutritif",
        agent_has_healthRisk_alert:
          "Cet élément nutritif soutient vos risques de santé",
        product_has_alert: "Alerte relative au produit",
        product_is_recommended: "Ce produit est recommandé pour vous",
        nutrient_is_recommended: "Top nutriment recommandé",
        nutrient_has_alert: "Alerte relative au nutriment",
        nutrient_contraindicated:
          "{{ nutrientName }} est contre-indiqué pour {{ symptomName }}",
        you_are_allergic_to: "Vous êtes allergique à ce produit: {{ name }}",
        you_are_intolerant_to: "Vous êtes intolérant à ce produit: {{ name }}",
        you_are_allergic_to_excipient:
          "You are allergic to this product excipient: {{ name }}",
        you_are_intolerant_to_excipient:
          "You are intolerant to this product excipient: {{ name }}",
      },
    },
  },
};

export { messages };
