export type Enviroments = "production" | "homologation" | "development";

const redirectToQuiz = () => {
  const env = process.env.REACT_APP_MODE;

  if (env === "production") {
    window.location.replace("https://quiz.kpsule.co/");
  } else if (env === "homologation") {
    window.location.replace("https://quizhml.kpsule.co/");
  } else {
    alert("Calling redirectToQuiz()");
  }
};

export default redirectToQuiz;
