import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  AgentFieldOfActionType,
  CompositionType,
} from "../../../../../api/types";
import { ReactComponent as AlertIcon } from "../../../../../assets/content/alert.svg";
import { ReactComponent as CapsuleIcon } from "../../../../../assets/content/capsule-orange.svg";
import { ReactComponent as HealthRiskIcon } from "../../../../../assets/content/health-risk.svg";
import { ReactComponent as NutritionalIcon } from "../../../../../assets/content/nutritional.svg";
import { ReactComponent as PillSynergyIcon } from "../../../../../assets/content/pill-synergy.svg";
import { ReactComponent as StarIcon } from "../../../../../assets/content/star-blue.svg";
import RoundedProgress from "../../../../../components/RoundedProgress";
import StandardTooltip from "../../../../../components/Tooltip";
import getDrugInteractionPhrasesFormatted from "../../../../../utils/getDrugInteractionPhrasesFormatted";
import getInnerHtmlInsideDiv from "../../../../../utils/getInnerHtmlInsideDiv";
import {
  AlertContainer,
  AlertIconContainer,
  CircleAlert,
  CircleSymptons,
  CompositionField,
  CompositionName,
  Container,
  ContainerCircleSymptons,
  ContainerIconMock,
  ContainerInfos,
  Fills,
  FooterComposition,
  IconContainerLeft,
  IconContainerRight,
  NutrientFills,
} from "./style";
// import { ModalComposition } from "./ModalComposition";
import { getAgentExtractTypeIcon } from "../../../../../utils/getAgentExtractTypeIcon";
import { getNutrientCategoryIcon } from "../../../../../utils/getNutrientCategoryIcon";

type ComponentCardProps = {
  component: CompositionType;
  viewMore: boolean;
  index: number;
  onComposition: () => void;
};

const ComponentCard = ({
  component,
  index,
  viewMore,
  onComposition,
}: ComponentCardProps) => {
  const [countAlert, setCountAlert] = useState<any>([]);
  const [isModalOpen, setIsModalopen] = useState(false);

  useEffect(() => {
    const ref = document.getElementById("modal");
  }, []);

  const {
    // dietInformation,
    healthRisks,
    nutrition,
    dosageAmountUnit,
    dosageAmountValue,
    fieldsOfAction,
    drugInteractions,
    hasDrugInteraction,
    // id,
    isInTopTen,
    name,
    score,
    extractType,
    form,
    // nutrientFills,
    // studiesId,
  } = component;

  // const {
  //   setStudiesID,
  //   setComponentName,
  //   setSuboutcomeIdScoreOrderList,
  //   setContraindicationSuboutcomeIndex,
  // } = useContext(StudiesContext);

  // function handleModalComposition() {
  //   setIsModalopen(prevState => !prevState)
  // }

  function handleCountAlert() {
    const alerts = fieldsOfAction.filter(
      (element) => element.isContraindication === true
    );
    setCountAlert([...countAlert, alerts]);
  }

  useEffect(() => {
    handleCountAlert();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const handleSeeAllRelatedPublications = () => {
  //   setComponentName(name);
  //   setStudiesID(studiesId);
  //   setSuboutcomeIdScoreOrderList(fieldsOfAction.map((elem) => elem.id));
  //   const indexes: number[] = [];
  //   fieldsOfAction.forEach((elem, index) => {
  //     if (elem.isContraindication) {
  //       indexes.push(index);
  //     }
  //   });
  //   setContraindicationSuboutcomeIndex(indexes);
  // };

  const { t } = useTranslation();

  // const sliderRef = createRef<HTMLDivElement>();

  const getIconColor = () => {
    if (component.drugInteractions.some((elem) => elem.action === "Avoid")) {
      return "red";
    }
    if (component.drugInteractions.some((elem) => elem.action === "Lower")) {
      return "orange";
    }
    if (component.drugInteractions.some((elem) => elem.action === "Favor")) {
      return "green";
    }
    return "gray";
  };

  const getDrugInteractionText = () => {
    const interactionsToAvoid = component.drugInteractions.filter(
      (elem) => elem.action === "Avoid"
    );
    if (interactionsToAvoid.length > 0) {
      const avoid = getDrugInteractionPhrasesFormatted(
        [interactionsToAvoid[0]],
        t
      );

      return avoid[0].phrase;
    }
    const interactionLower = component.drugInteractions.filter(
      (elem) => elem.action === "Lower"
    );
    if (interactionLower.length > 0) {
      const lower = getDrugInteractionPhrasesFormatted(
        [interactionLower[0]],
        t
      );
      return lower[0].phrase;
    }

    const interactionFavor = component.drugInteractions.filter(
      (elem) => elem.action === "Favor"
    );

    if (interactionFavor.length > 0) {
      const favor = getDrugInteractionPhrasesFormatted(
        [interactionFavor[0]],
        t
      );
      return favor[0].phrase;
    }

    return "";
  };

  const getSynergyPhrase = (field: AgentFieldOfActionType) => {
    if (!field.hasSynergy) {
      return "";
    }

    const { phrase, activeAgentName, passiveAgentName } = field.synergies[0];

    return phrase
      .replace(activeAgentName, `<strong>${activeAgentName}</strong>`)
      .replace(passiveAgentName, `<strong>${passiveAgentName}</strong>`);
  };

  function handleContraindicationAlert() {
    const contraindications = fieldsOfAction.filter(
      (element) => element.isContraindication
    );
    if (contraindications.length > 1) {
      return t("hovers.agent_has_alerts");
    } else {
      return t("hovers.agent_has_alert");
    }
  }

  function handleDrugInterationAlert() {
    const drugInteractionsAlerts = drugInteractions.filter(
      (element) => element
    );
    if (drugInteractionsAlerts.length > 1) {
      return t("hovers.agent_has_drug_interations");
    } else {
      return t("hovers.agent_has_drug_interation");
    }
  }

  return (
    <>
      <Container viewMore={viewMore} index={index}>
        <Fills>
          <IconContainerLeft>
            {isInTopTen && (
              <AlertContainer
                id={`is-in-top-ten-${component.id}`}
                // isInTopTen={isInTopTen}
              >
                <StarIcon />
              </AlertContainer>
            )}
            {isInTopTen && (
              <StandardTooltip
                anchor={`is-in-top-ten-${component.id}`}
                color="blue"
                text={t("hovers.nutrient_is_recommended")}
                place="right"
              />
            )}

            {healthRisks?.length > 0 && (
              <AlertContainer
                // color={getIconColor()}
                id={`health-risk-${component.id}`}
                // isInTopTen={isInTopTen}
              >
                <HealthRiskIcon />
              </AlertContainer>
            )}

            {healthRisks?.length > 0 && (
              <StandardTooltip
                anchor={`health-risk-${component.id}`}
                color="blue"
                text={t("hovers.agent_has_healthRisk_alert")}
                place="right"
              />
            )}

            {fieldsOfAction?.some(
              (element) => element.synergies.length > 0
            ) && (
              <AlertContainer
                id={`synergy-${component.id}`}
                // isInTopTen={isInTopTen}
              >
                <PillSynergyIcon />
              </AlertContainer>
            )}

            {fieldsOfAction?.some(
              (element) => element.synergies.length > 0
            ) && (
              <StandardTooltip
                anchor={`synergy-${component.id}`}
                color="blue"
                text={t("hovers.agent_has_synergy_alert")}
                place="right"
              />
            )}

            {nutrition.length > 0 && (
              <AlertContainer
                // color={getIconColor()}
                id={`nutrition-${component.id}`}
              >
                <NutritionalIcon />
              </AlertContainer>
            )}

            {nutrition?.length > 0 && (
              <StandardTooltip
                anchor={`nutrition-${component.id}`}
                color="blue"
                text={t("hovers.agent_has_nutrition_deficient_alert")}
                place="right"
              />
            )}
          </IconContainerLeft>

          <ContainerInfos>
            <RoundedProgress
              size={70}
              progress={component.score}
              grayForeground
              thickness={6}
            >
              <ContainerIconMock>
                {!!component.extractTypeId
                  ? getAgentExtractTypeIcon(component.extractTypeId)
                  : getNutrientCategoryIcon(component.subcategoryId)}
                {/* <IconMock /> */}
              </ContainerIconMock>
            </RoundedProgress>
            <CompositionField>
              <label>{`${parseFloat(
                dosageAmountValue.toFixed(2)
              )} ${dosageAmountUnit}`}</label>
            </CompositionField>
            <CompositionName>
              {`${name}`}
              {extractType ? <label>{` - ${extractType}`}</label> : ``}
            </CompositionName>
            <NutrientFills
              contentLength={
                t("screens.productDetail.fills_need_day", {
                  nutrientFills: Math.round(score),
                }).length
              }
            >
              {t("screens.productDetail.fills_need_day", {
                nutrientFills: Math.round(score),
              })}
            </NutrientFills>
          </ContainerInfos>

          <IconContainerRight>
            {fieldsOfAction?.some((element) => element.isContraindication) && (
              <AlertContainer
                id={`contraindication-alert-${component.id}`}
                // isInTopTen={isInTopTen}
              >
                <AlertIcon />

                {fieldsOfAction?.filter((element) => element.isContraindication)
                  .length > 1 && (
                  <CircleAlert value={countAlert.length + 1}>
                    <label>{countAlert.length + 1}</label>
                  </CircleAlert>
                )}
              </AlertContainer>
            )}

            {fieldsOfAction?.some((element) => element.isContraindication) && (
              <StandardTooltip
                anchor={`contraindication-alert-${component.id}`}
                color="orange"
                text={handleContraindicationAlert()}
                place="right"
              />
            )}

            {hasDrugInteraction && (
              <AlertIconContainer
                color={getIconColor()}
                id={`drug-interaction-${component.id}`}
              >
                <CapsuleIcon />
              </AlertIconContainer>
            )}

            {hasDrugInteraction && (
              <StandardTooltip
                anchor={`drug-interaction-${component.id}`}
                color={getIconColor()}
                text={handleDrugInterationAlert()}
                // children={
                //   <div
                //     dangerouslySetInnerHTML={{
                //       __html: getInnerHtmlInsideDiv(getDrugInteractionText()),
                //     }}
                //   ></div>
                // }
                place="right"
              />
            )}
          </IconContainerRight>
        </Fills>
        <FooterComposition>
          <ContainerCircleSymptons>
            <CircleSymptons value={fieldsOfAction?.length}>
              <label>{fieldsOfAction?.length}</label>
            </CircleSymptons>
            <label>Symptons</label>
          </ContainerCircleSymptons>

          <label onClick={onComposition}>See More</label>
        </FooterComposition>
      </Container>
      {component?.fieldsOfAction.map((field) => {
        if (!field.hasSynergy) return <> </>;
        return (
          <StandardTooltip
            anchor={`${component.id}-synergy-${field.id}-${field.categoryId}`}
            color="blue"
            text={field.synergies[0].phrase}
            place="top"
            children={
              <div
                dangerouslySetInnerHTML={{
                  __html: getInnerHtmlInsideDiv(getSynergyPhrase(field)),
                }}
              ></div>
            }
          />
        );
      })}

      {component?.fieldsOfAction.map((field) => {
        if (!field.isContraindication) return <> </>;
        return (
          <StandardTooltip
            anchor={`${component.id}-contraindication-${field.id}-${field.categoryId}`}
            color="orange"
            text={t("hovers.nutrient_contraindicated", {
              nutrientName: component.name,
              symptomName: field.name,
            })}
            place="top"
            children={
              <div
                dangerouslySetInnerHTML={{
                  __html: getInnerHtmlInsideDiv(
                    t("hovers.nutrient_contraindicated", {
                      nutrientName: component.name,
                      symptomName: field.name,
                    })
                  ),
                }}
              ></div>
            }
          />
        );
      })}
    </>
  );
};

export default ComponentCard;
