const messages = {
  pt: {
    translation: {
      components: {
        sidebar: {
          home: "Início",
          productRecommendation: "Produtos Recomendados",
          nutritionalNeeds: "Necessidades Nutricionais",
          favoriteFoods: "Alimentos Recomendados",
          recommendedPacks: "Pacotes Recomendados",
        },
        shoppingCartSidebar: {
          your_basket: "Seu carrinho",
          of_your_nutritional_needs: " das suas necessidades nutricionais",
          total: "Total",
          your_compatibility: "Sua compatibilidade",
          validate: "Validar",
          remove_all: "Remover todos",
        },
        navMobile: {
          the_right_supplements: "Os suplementos certos para você",
        },
        modal: {
          already_added:
            'Você já adicionou "{{ productName }}" ao seu carrinho. Você deseja removê-lo?',
          yes: "Sim",
          no: "Não",
          read_more: "Saiba mais",
          related_publications: "Estudos Relacionados: {{ componentName }}",
          publications_tab: "Estudos Científicos",
        },
        filterSidebar: {
          find_the_best:
            "Encontre os melhores produtos de acordo com suas necessidades",
          select_below: "Selecione abaixo",
          your_symptoms: "Sintomas específicos",
          symptoms_category: "Problemas de Saúde",
          your_health_goals: "Seus Objetivos de Saúde",
          clear_filters: "Limpar filtros",
          apply: "Aplicar",
          price: "Preço",
        },
      },
      languages: {
        actualInitials: "pt",
        actual: "Português",
        fr: "Francês",
        pt: "Português",
        en: "Inglês",
      },
      healthGoals: {
        energy: "Aumentar a Energia",
        boost: "Performance Física",
        beauty: "Beleza",
        weight: "Perda de Peso",
        digestion: "Regular a Digestão",
        cognition: "Aumentar a Cognição",
        mood: "Equilibrar o Humor",
        longevity: "Promover a longevidade",
        sleep: "Melhorar  o sono",
        immunity: "Melhorar a imunidade",
      },
      screens: {
        home: {
          hello: "Bem-vindo, {{ name }}!",
          hello_male: "Bem-vindo, {{ name }}!",
          hello_female: "Bem-vinda, {{ name }}!",
          title: "Suas recomendações",
          based_on: "baseadas em {{ count }} Estudos Científicos",
          based_of: "Baseadas em",
          best_products: "Melhores Produtos",
          best_nutrients: "Melhores Nutrientes",
          see_our_pack: "Veja nossos pacotes recomendados",
          see_other_possibilities:
            "Veja outras possibilidades de <span>Melhorar Sua Saúde</span>",
          banner: {
            pack_essential: "Pacote Essencial",
            optimal: "Pacote Otimizado",
            premium: "Pacote Premium",
            recommended_packs: "Pacotes recomendados",
            pack_supports:
              "O <strong>{{ packName }}</strong> supre <strong>{{ packPercentage }}%</strong> das suas Necessidades de Saúde",
            add_to_cart: "Adicionar ao Carrinho",
            see_all: "Ver tudo",
            your_compatibility: "Sua Compatibilidade",
            in_cart: "No Carrinho",
            products: "Produtos",
          },
          cardBests: {
            view_all: "Ver todos",
          },
          tabs: {
            bestProducts: {
              based_of_products: "{{ count }} Produtos",
              see_all: "Ver tudo",
              see_the_most: "Veja os suplementos mais compatíveis baseados em",
              your_goals_and_needs: "seus Objetivos e Necessidades de Saúde:",
              your_compatibility: "Sua Compatibilidade",
              health_goals: "Objetivos de Saúde",
              symptoms: "Sintomas",
              learn_more: "Saiba mais",
              in_cart: "No Carrinho",
            },
            bestNutrients: {
              based_of_nutrients: "{{ count }} Nutrientes",
              see_all: "Ver tudo",
              see_the_most: "Veja os suplementos mais compatíveis baseados em",
              your_goals_and_needs: "seus Objetivos e Necessidades de Saúde:",
              your_compatibility: "Sua Compatibilidade",
              health_goals: "Objetivos de Saúde",
              symptoms: "Sintomas",
              learn_more: "Saiba Mais",
              best_products: "Ver Produtos",
            },
          },
        },
        products: {
          best_products_from_a_base:
            "<strong>Melhores suplementos</strong> dentre uma base de <label>{{ count }} produtos</label>",
          find_the_best:
            "Encontre os melhores produtos de acordo com suas necessidades.",
          select_below: "Selecione abaixo:",
          amount_available_products: "{{ count }} produtos disponíveis",
          search_product: "Pesquisar produto",
          filter: "Filtro",
          your_symptoms: "Seus sintomas",
        },
        nutritionalNeeds: {
          best_nutrients: "Nutrientes recomendados para você",
          find_the_best:
            "Encontre os melhores nutrientes de acordo com suas necessidades.",
          search_nutrient: "Pesquisar nutriente",
          filter: "Filtro",
          your_symptoms: "Seus sintomas",
        },
        productDetail: {
          alerts: "Alertas",
          precautions: "Precauções",
          your_compatibility: "Sua Compatibilidade",
          best_products: "Melhores Produtos",
          health_goals: "Objetivos de Saúde",
          symptoms: "Sintomas",
          composition: "Composição",
          fields_of_action: "Campos de Ação",
          excipients: "Excipientes",
          capsules_per_day: "{{ count }} cápsulas por dias",
          capsule_per_day: "{{ count }} cápsula por dia",
          month: "mês",
          months: "meses",
          week: "semana",
          weeks: "semanas",
          and: "e",
          in_cart: "No Carrinho",
          add_to_cart: "Adicionar ao Carrinho",
          fills_need_day:
            "Suplementa {{ nutrientFills }}% da sua necessidade diária",
          support_your_deficiencies:
            "Suplementa suas Deficiências Nutricionais a seguir",
          support_your_health: "Suplementa seus aspectos de Saúde a seguir",
          your_diet_supply:
            "Sua dieta compreende apenas {{score}}% da necessidade diária em {{ name }}!",
          symptom: "Sintoma",
          less_details: "Menos detalhes",
          more_details: "Mais detalhes",
          has_nutrients_in_this_category:
            "Possui <label>{{ amountOfNutrients }}</label> Nutrientes nessa Categoria",
          has_nutrient_in_this_category:
            "Possui <label>{{ amountOfNutrients }}</label> Nutriente nessa Categoria",
          see_all_related_publications: "Ver todos os Estudos relacionados",
          no_additional_information:
            "Sem informações adicionais relacionadas a este Nutriente,",
          for_each_capsule: "Por cada <span>cápsula</span>",
          other_ingredients: "Outros ingredientes",
          per_dose: "por dose",
        },
        recommendedPack: {
          alerts: "Alertas",
          precautions: "Precauções",
          choose_your_pack: "Escolha seu Pacote",
          essential_pack: "Pacote Essencial",
          optimal_pack: "Pacote Otimizado",
          premium_pack: "Pacote Premium",
          pack_supports:
            "<span>{{ packName }}</span> suplementa <span>{{ score }}%</span> das suas Necessidades de Saúde",
          score_health_needs: "{{ score }}%",
          duration: "Duração",
          month: "mês",
          months: "meses",
          in_cart: "No Carrinho",
          add_to_cart: "Adicionar ao Carrinho",
          dosage_of: "Dosagem de",
          products_in_pack: "10 Produtos dans le pack",
          your_benefits: "Benefícios",
          field_of_action: "Campos de Ação",
          learn_more: "Saiba mais",
          your_compatibility: "Sua Compatibilidade",
          health_goals: "Objetivos de Saúde",
          symptoms: "Sintomas",
        },
        nutrientDetail: {
          best_products: "Melhores Produtos",
          best_products_with_nutrient: "Melhores Produtos com {{ name }}",
          nutrient_detail: "Detalhes do Nutriente",
          diet_deficiency:
            "Sua dieta compreende apenas {{ nutritionDeficiency }}% da dose diária recomendada para esse Nutriente",
          health_goals: "Objetivos de Saúde",
          symptoms: "Sintomas",
          fields_of_action: "Campos de Ação",
          your_compatibility: "Sua Compatibilidade",
          products_containing: "Produtos contendo esse Nutriente",
          day: "dia",
          in_cart: "No Carrinho",
          add_to_cart: "Adicionar ao Carrinho",
          better_compatibility: "Melhor compatibilidade",
          best_report: "Melhor custo-benefício",
          support_your_following: "Suplementa seus aspectos de Saúde a seguir",
          symptom: "Sintoma",
          less_details: "Menos detalhes",
          more_details: "Mais detalhes",
        },
        validate: {
          redirect: "Você será redirecionado para a finalização da compra",
        },
      },
      common: {
        view_more: "Ver mais",
        view_less: "Ver menos",
        alerts: "Alertas",
        precautions: "Precauções",
        contraindication: "Contraindicação",
        study: "Estudo",
        studies: "Estudos",
        and: "e",
        excipient: "Excipiente",
        products: "Produtos",
        day: "dia",
        drug_interaction: "Interação Medicamentosa",
        allergy: "Alergia",
        intolerance: "Intolerância",
      },
      hovers: {
        agent_has_drug_interation:
          "Este nutriente tem interação com medicamento",
        agent_has_drug_interations:
          "Este nutriente tem interações com medicamento",
        agent_has_alert: "Alerta associado a este nutriente",
        agent_has_alerts: "Alertas associados a este nutriente",
        agent_has_synergy_alert: "This component has synergy",
        agent_has_nutrition_deficient_alert:
          "Você está deficiente neste nutriente",
        agent_has_healthRisk_alert:
          "Este nutriente suporta seus riscos de saúde",
        product_has_alert: "Esse produto possui um alerta",
        product_is_recommended: "Esse produto é recomendado para você",
        nutrient_is_recommended: "Um dos melhores nutrientes para você",
        nutrient_has_alert: "Esse nutriente possuio um alerta",
        nutrient_contraindicated:
          "{{ nutrientName }} é contraindicado para {{ symptomName }}",
        you_are_allergic_to: "Você é alérgico a este nutriente: {{ name }}",
        you_are_intolerant_to:
          "Você é intolerante a este nutriente: {{ name }}",
        you_are_allergic_to_excipient:
          "You are allergic to this product excipient: {{ name }}",
        you_are_intolerant_to_excipient:
          "You are intolerant to this product excipient: {{ name }}",
      },
    },
  },
};

export { messages };
