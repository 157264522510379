import styled from "styled-components";
import bannerBackground from "../../../../../assets/banners/banner-background.png";
import { getGradient } from "../../../../../utils/getBannerGradient";

export const Container = styled.div<{ viewMore: boolean; index: number }>`
  width: 313px;
  height: 222px;
  display: flex;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  flex-direction: column;
  margin-top: 1.5rem;
  position: relative;
  ${({ theme }) => theme.shadow}
  ${({ viewMore, index }) =>
    !viewMore &&
    index > 7 &&
    `
    opacity: 0;
    height: 0;
    overflow: hidden;
    padding: 0;
    margin-top: 0;
    `} 
    
  section {
    margin-right: 12px;
    margin-top: 50px;
  }

  @media (width: 1366px) {
    width: 300px;
  }
`;

export const ScrollButtonsContainer = styled.div<{
  hasDietInformation: boolean;
}>`
  position: absolute;
  top: 16px;
  left: 450px;
`;

export const FieldsOfActionLabel = styled.label`
  position: absolute;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.gray};
  top: 24px;
  left: 320px;
`;

export const SeeAllRelatedPublicationsLabel = styled.label<{
  hasDietInformation: boolean;
}>`
  cursor: pointer;
  position: absolute;
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.selected};
  bottom: 16px;
  left: 322px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  > svg {
    margin-left: 8px;
  }

  :hover {
    text-decoration: underline;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const OldContainer = styled.div.attrs(
  (props: { viewMore: boolean; index: number }) => props
)`
  margin-top: 1.5rem;
  width: 100%;
  height: 236px;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 8px;
  transition: opacity 1s ease-out;
  opacity: 1;
  ${({ theme }) => theme.shadow}
  ${({ viewMore, index }) =>
    !viewMore &&
    index > 2 &&
    `
    opacity: 0;
    height: 0;
    overflow: hidden;
    padding: 0;
    margin-top: 0;
    `}
`;

export const IconContainerLeft = styled.div`
  width: 35px;
  height: 77px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
  left: 24px;

  > div {
    :not(:first-child) {
      /* margin-top: 5px; */
    }
  }
`;

export const IconContainerRight = styled.div`
  width: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 24px;

  > div {
    :not(:first-child) {
      margin-top: 5px;
    }
  }
`;

export const AlertIconContainer = styled.div`
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  filter: drop-shadow(0px 2.31034px 2.31034px rgba(0, 0, 0, 0.16));

  > svg {
    height: 20px;
    width: 20px;
  }
  background: #fff;
`;

export const AlertContainer = styled.div<{ isInTopTen?: boolean }>`
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  position: relative;
  filter: drop-shadow(0px 2.31034px 2.31034px rgba(0, 0, 0, 0.16));

  > svg {
    height: 20px;
    width: 20px;
  }

  background: #fff;
`;

export const Fills = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  padding: 16px;

  > label {
    margin-top: 1rem;
    font-size: 18px;
    color: ${({ theme }) => theme.colors.black};
    font-weight: bold;
    text-align: center;
  }
`;

export const NutrientFills = styled.span<{ contentLength: number }>`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  ${({ contentLength }) => contentLength > 50 && "font-size: 14px;"}
  color: ${({ theme }) => theme.colors.black};
  margin-top: -4px;
  opacity: 0.7;
`;

export const ContainerInfos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

export const FooterComposition = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 16px;

  > label {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.blueLight};
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      filter: brightness(0.7);
    }
  }
`;

export const CircleSymptons = styled.div<{ value: number }>`
  width: 25px;
  height: 25px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180.32deg, #2effaf 15.34%, #00aaff 98.15%);

  > label {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: ${({ value }) => (value < 10 ? "16px" : "14px")};
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const CircleAlert = styled.div<{ value: number }>`
  width: 16px;
  height: 16px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(228.69deg, #f68e61 24.77%, #ff6737 86.3%);
  box-shadow: 0px 2px 2px rgba(84, 89, 118, 0.46);
  position: absolute;
  top: -8px;
  right: -5px;

  > label {
    font-family: "Roboto";
    font-style: normal;
    font-weight: bold;
    font-size: ${({ value }) => (value < 10 ? "10px" : "9px")};
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const ContainerCircleSymptons = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  > label {
    font-size: 14px;
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const ContainerIconMock = styled.div`
  svg {
    width: 35px;
    height: 35px;
  }
`;

export const ScrollContainer = styled.div`
  margin-top: -10px;
  width: 100%;
`;

export const CompositionField = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  > label {
    font-size: 16px;
    width: 80%;
    text-align: center;
    color: ${({ theme }) => theme.colors.blueLight};
    font-weight: bold;
  }
`;

export const CompositionName = styled.label`
  font-size: 16px;
  width: 150%;
  text-align: center;
  color: ${({ theme }) => theme.colors.black};
  font-weight: bold;

  > label {
    font-weight: normal;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.black};
    opacity: 0.7;
  }
`;

export const FieldsOfAction = styled.div`
  margin-left: 1rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  pointer-events: none;
  flex: auto;
  overflow-x: auto;
  position: relative;
  z-index: 85;
  padding-left: 12px;
  padding-right: 12px;

  > label {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 0.5rem;
    color: ${({ theme }) => theme.colors.black};
  }
  > div {
    margin-top: 10px;
  }
`;

export const Field = styled.div<{ border?: "gradient" | "pink" | "none" }>`
  padding: 2px;
  height: 97px;
  width: 162px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 10px;

  flex-shrink: 0;

  border: 1px transparent;
  border-radius: 8px;
  background-origin: border-box;
  background-clip: content-box, border-box;

  box-shadow: 1px 4px 6px rgba(0, 0, 0, 0.2);
  ${({ border }) => {
    switch (border) {
      case "gradient":
        return `background-image: linear-gradient(white, white),
                radial-gradient(circle at top left, rgba(49, 53, 198, 1), rgba(0, 170, 255, 1));`;
      case "pink":
        return `background-image: linear-gradient(white, white),
                radial-gradient(circle at top left, #F68E61, #FF6737);`;
      default:
        return `background-image: linear-gradient(white, white),
        radial-gradient(circle at top left, #2EFFAF, #00AAFF);`;
    }
  }};

  > div {
    // border: 2px solid red;
  }

  > svg {
    top: -10px;
    right: 8px;
    z-index: 85;
    position: absolute;
    overflow: visible;
  }
`;

export const FieldName = styled.label`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  text-align: left;
  margin-left: 5px;
  margin-right: 10px;
  color: ${({ theme }) => theme.colors.gray};
  width: 82px;
  padding-left: 4px;
  padding-right: 4px;
`;

export const Background = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70%;
  width: 80%;
`;

export const ProgressContainer = styled.div<{ border?: "gradient" | "pink" }>`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  > svg * {
    fill: ${({ border }) => border === "pink" && "#ED9F9F"};
  }
`;

export const MockCircle = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
`;

export const MockName = styled.div`
  margin-top: 1rem;
  height: 4px;
  padding: 4px;
  width: 40%;
  background: ${({ theme }) => theme.colors.gray};
  opacity: 0.8;
  border-radius: 8px;
`;

export const AbsoluteIcon = styled.div``;

export const DietInformationCarouselContainer = styled.div`
  min-width: 222px;
  position: relative;
`;

export const DietInformation = styled.div<{
  score: number;
  backgroundType: string;
  isSelected: boolean;
}>`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  ${({ backgroundType, score, theme }) => {
    if (backgroundType === "gray")
      return `background: ${theme.colors.grayBgButton}`;

    return `background: url(${bannerBackground}),
    linear-gradient(
      ${getGradient(score, backgroundType)}
    )`;
  }};
  display: flex;
  flex-direction: column;
  justify-content: ${({ backgroundType }) =>
    backgroundType === "gray" ? "center" : "space-evenly"};
  align-items: center;
  position: absolute;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;

  ${({ isSelected }) =>
    isSelected &&
    `
      z-index: 85;
      opacity: 0.8;
  `}
`;

export const DietInformationProgressIcon = styled.div`
  height: 80px;
  width: 80px;
`;

export const DietInformationNoProgressIcon = styled.div`
  height: 80px;
  width: 80px;
`;

export const DietInformationTitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
`;

export const DietInformationTitle = styled.span`
  width: 100%;
  text-align: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.white};
`;

export const DietInformationNoContentTitle = styled.span`
  width: 100%;
  text-align: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #79797f;
`;

export const DietInformationCarouselButtons = styled.div`
  height: 4px;
  width: 100%;
  padding: 2px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const CarouselButton = styled.div<{ actual: number; value: number }>`
  height: 4px;
  width: 24px;
  background: ${({ theme, actual, value }) =>
    actual === value ? theme.colors.white : "transparent"};
  border-radius: 8px;
  border: 1px solid white;
  :not(:last-child) {
    margin-right: 4px;
  }

  cursor: pointer;
`;

export const DietInformationImage = styled.img``;

export const ArrowsContainer = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 4px;
  z-index: 450;
  > * {
    cursor: pointer;
  }
`;
