import { AlertContainer, AlertIconContainer } from "../../../style";
import { Card, SubTitle, TextContainer, Title } from "./style";
import { ReactElement } from "react";

interface InformationCardProps {
  componentId: number
  topTen?: boolean
  text: string
  hasSubTitle?: string
  icon: ReactElement
}

export function InformationCard({ componentId, topTen = true, text, hasSubTitle, icon }: InformationCardProps) {
  return (
    <Card type={text}>
      <AlertContainer
        id={`is-in-top-ten-${componentId}`}
        isInTopTen={topTen}
      >
        {icon}
      </AlertContainer>

      <TextContainer>
        <Title>{`${text}`}</Title>
        {hasSubTitle && (
          <SubTitle>{`${hasSubTitle}`}</SubTitle>
        )}
      </TextContainer>
    </Card>
  )
}