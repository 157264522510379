import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import { OtherIngredientType } from "../../../../api/types";
import ViewMoreButton from "../../../../components/Button/ViewMore";
import { Title } from "../../style";
import OtherIngredientCard from "./components/OtherIngredientCard";
import { OtherIngredientsContainer } from "./style";

interface OtherIngredientsProps {
  otherIngredients: OtherIngredientType[];
  executeScroll: () => void;
}

const OtherIngredients = ({
  otherIngredients,
  executeScroll,
}: OtherIngredientsProps) => {
  const theme = useTheme();
  const [viewMore, setViewMore] = useState(false);

  const { t } = useTranslation();

  const toggleViewMore = () => {
    setViewMore(!viewMore);
    if (!viewMore) {
      setTimeout(() => executeScroll(), 100);
    }
  };

  return (
    <OtherIngredientsContainer>
      <Title>{t("screens.productDetail.other_ingredients")}</Title>
      {otherIngredients.map((otherIngredient, index) => (
        <OtherIngredientCard
          key={index}
          title={otherIngredient.name}
          description={`${otherIngredient.dosageAmountValue} ${
            otherIngredient.dosageAmountUnit
          } ${t("screens.productDetail.per_dose")}`}
          index={index}
          viewMore={viewMore}
        />
      ))}

      {otherIngredients.length > 3 && (
        <ViewMoreButton isOpen={viewMore} onClick={toggleViewMore} />
      )}
    </OtherIngredientsContainer>
  );
};

export default OtherIngredients;
