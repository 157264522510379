import styled from "styled-components";

export const Card = styled.div<{type: string}>`
  min-width: ${({ type }) => type === "Top 10 of your needs" ? "213px" : "245px"};
  height: 75px;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.3);
  margin-bottom: 1rem;
`;

export const TextContainer = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.black};
`;

export const Title = styled.label`
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};
`;

export const SubTitle = styled.p`
  width: 170px;
  margin-top: 4px;
`;