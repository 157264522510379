import React, { useState } from "react";
import { useNavigate } from "react-router";
import { TabPanel } from "react-tabs";
import { ProductProps } from "../../../../../../../../api/types";
import { ReactComponent as HeartIcon } from "../../../../../../../../assets/content/tab-icon-heart.svg";
import { ReactComponent as BookIcon } from "../../../../../../../../assets/content/tab-icon-book.svg";
import { ReactComponent as HealthRiskIcon } from "../../../../../../../../assets/content/health-risk.svg";
import { ReactComponent as NutritionalIcon } from "../../../../../../../../assets/content/nutritional.svg";



import {
  Circle,
  Flag,
  FlagContainer,
  IconContainer,
  TabListContent,
  TabsContainer,
  UnorderedTab,
} from "./style";

interface TabViewProps {
  labels: string[];
  contents: React.ReactNode[];
  learnMore?: string;
  selectedProduct?: ProductProps;
  controlledContentSelected?: string;
  setControlledContentSelected?: React.Dispatch<React.SetStateAction<string>>;
  onTabClick?: (label: string) => void;
  labelsCounts?: number[];
  withOutGapAndHeight?: boolean;
}

const TabView: React.FC<TabViewProps> = ({
  contents,
  labels,
  learnMore,
  selectedProduct,
  controlledContentSelected,
  setControlledContentSelected,
  onTabClick,
  labelsCounts,
  withOutGapAndHeight = false,
}) => {
  const navigate = useNavigate();

  const [contentSelected, setContentSelected] = React.useState(labels[0].length > 0 ? labels[0] : labels[1]);
  const [clicked, setClicked] = useState()

  const getContent = () => {
    if (setControlledContentSelected) {
      return controlledContentSelected;
    } else {
      return contentSelected;
    }
  };

  const handleClick = (label: string) => {

    if (onTabClick) onTabClick(label);

    if (setControlledContentSelected) {
      setControlledContentSelected(label);
    } else {
      setContentSelected(label);
    }
  };

  return (
    <TabsContainer withOutGapAndHeight={withOutGapAndHeight} selectedIndex={labels?.indexOf(getContent() as string)}>
      <TabListContent>
        {labels?.map((label, index) => {
          return (
            <UnorderedTab
              key={index}
              onClick={() => handleClick(label)}
              selected={getContent() === label}
              onSelect={() => null}
              hasLabel={label.length > 0}
            >
              <FlagContainer>
                <label>
                  <IconContainer selected={getContent() === label}>
                    {
                      label === "Fields of Action" ? (
                        <HeartIcon />
                      ) : label === "Health Risk" ? (
                        <HealthRiskIcon />
                      ) : label === "Nutrition" ? (
                        <NutritionalIcon />
                      ) : label === "Publication" ? (
                        <BookIcon />
                      ) : (
                        <></>
                      )
                    }
                  </IconContainer>
                  <span>{label}</span>
                  {labelsCounts && labelsCounts[index] > 0 && (
                    <Circle value={labelsCounts[index]}>
                      <label>{labelsCounts[index]}</label>
                    </Circle>
                  )}
                </label>

                <Flag show={getContent() === label} />
              </FlagContainer>
            </UnorderedTab>
          );
        })}

        {/* <LearnMore
          onClick={() => {
            navigate("/product-details", {
              state: selectedProduct,
              // replace: true,
            });
          }}
        >
          {learnMore}
        </LearnMore> */}
      </TabListContent>

      {contents.map((content, index) => (
        <TabPanel key={index}>{content}</TabPanel>
      ))}
    </TabsContainer>
  );
};
export default TabView;
