import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import {
  CompositionType,
  ExcipientType,
  OtherIngredientType,
} from "../../../../api/types";
import { ReactComponent as PillsIcon } from "../../../../assets/delivery-methods/pill-filled.svg";
import ViewMoreButton from "../../../../components/Button/ViewMore";
import CardSimple from "../../../../components/Cards/Simple";
import StandardTooltip from "../../../../components/Tooltip";
import { getExcipientIcon } from "../../../../utils/getExcipientIcon";
import OtherIngredients from "../../components/OtherIngredients";
import { ExcipientCard, GapDiv } from "../../style";
import ComponentCard from "./components/ComponentCard";
import { CompositionLabel, CompositionLabelContainer, ContainerCompositionCards } from "./style";
import { ModalComposition } from "./components/ModalComposition";

type CompositionProps = {
  composition: CompositionType[];
  otherIngredients: OtherIngredientType[];
  excipients: ExcipientType[];
  executeScroll: () => void;
  deliveryMethod: string;
};

const Composition: React.FC<CompositionProps> = ({
  composition,
  otherIngredients,
  excipients,
  executeScroll,
  deliveryMethod,
}) => {
  const { t } = useTranslation();

  const [viewMore, setViewMore] = useState(false);

  const theme = useTheme();

  const toggleViewMore = () => {
    if (viewMore) {
      executeScroll();
    }
    setViewMore(!viewMore);
  };

  const otherIngredientsRef = useRef<HTMLDivElement>(null);
  const executeOtherIngredientsScroll = () => {
    if (otherIngredientsRef && otherIngredientsRef.current) {
      otherIngredientsRef.current.scrollIntoView();
    }
  };

  const [openCompositionModal, setOpenCompositionModal] = useState(false);
  const [selectedComposition, setSelectedComposition] = useState<CompositionType>()

  const handleOpenCompositionModal = (composition: CompositionType) => {
    setSelectedComposition(composition);
    setOpenCompositionModal(true);
  };

  const handleCloseCompositionModal = () => {
    // setSelectedComposition();
    setOpenCompositionModal(false);
  };

  return (
    <div>
      <CompositionLabelContainer>
        <PillsIcon />
        {/* // TODO implementar depois uma fução para Delivery Method */}
        <CompositionLabel
          dangerouslySetInnerHTML={{
            __html: t("screens.productDetail.for_each_capsule"),
          }}
        ></CompositionLabel>
      </CompositionLabelContainer>

      <ContainerCompositionCards>
        {composition
          .filter((component) => component.fieldsOfAction.length > 0) // REMOVER ESSE FILTRO DEPOIS QUE CORRIGIR A API
          .map((component, index) => {
            return (
              <ComponentCard
                key={index}
                component={component}
                viewMore={viewMore}
                index={index}
                onComposition={() => handleOpenCompositionModal(component)}
              />
            );
          })}

      </ContainerCompositionCards>

      {composition.length > 8 && (
        <ViewMoreButton isOpen={viewMore} onClick={toggleViewMore} horizontalAlign/>
      )}

      <div ref={otherIngredientsRef} />
      {otherIngredients.length > 0 && (
        <OtherIngredients
          otherIngredients={otherIngredients}
          executeScroll={executeOtherIngredientsScroll}
        />
      )}

      {excipients.length > 0 && (
        <CardSimple title={t("screens.productDetail.excipients")}>
          <GapDiv>
            {excipients.map((excipient, index) => {
              return (
                <>
                  <ExcipientCard key={index} importance={excipient.importance}>
                    <div>{getExcipientIcon(excipient.iconName, "blue")}</div>
                    <label id={`${excipient.name}`}>{excipient.name}</label>
                  </ExcipientCard>
                  <StandardTooltip
                    anchor={`${excipient.name}`}
                    text={`${excipient.name}`}
                    color="gray"
                  />
                </>
              );
            })}
          </GapDiv>
        </CardSimple>
      )}

      <ModalComposition
        id="modal"
        isOpen={openCompositionModal}
        onModalClose={handleCloseCompositionModal}
        component={selectedComposition!}
      />
    </div>
  );
};

export default Composition;
